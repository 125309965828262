import React, { Component } from "react";

import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Menu,
    Image,
    Segment
  } from "semantic-ui-react";
  
class About extends Component {
    render(){
        return(
            <Container>
            <Header as="h2">
                About Dometechnics
            </Header>
            <p>Steve Pierce, is the founder of Dometechnics and has over 25 years experience as a software engineer, web developer, project manager, and ambient music composer.</p>

<p>After working as a programmer for some of the top US and European organizations—including the BBC in London, Ford in Cologne, Germany, and at various Bay Area start-ups—Steve began creating the DOME whilst pursuing two masters degrees in digital music at the University of Leeds (UK) and at Dartmouth College, NH.</p>

<p>Initially inspired by his passion for creating film scores while earning a BA in Cinema & Music at USC, he began to see how his invention could be used in commercial products to create better user experiences and allow game developers to access affordable compositions. Steve is currently refining the DOME prototype and is open to enquiries by investors and industry partners.</p>

            </Container>
        )
    }
}

export default About;