import React, { Component } from "react";

import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Menu,
    Image,
    Segment
  } from "semantic-ui-react";
  
class Technology extends Component {
    render(){
        return(
            <Container>
            <Header as="h2">
                Dynamic Object Music Engine (DOME)
            </Header>

            <p>The DOME system is based on research into the automation possibilities of computer-assisted music composition in cinematic musical scores and videogame music.</p>

<Header as="h3">Game music can be boring and repetitive.</Header>

<p>Due to hardware restrictions, budget constraints, or lack of development time, game music can often be highly repetitive, linear, and/or unresponsive to the user. A serious gamer can spend anywhere from 20 to 40+ hours a week playing a single game so the aural experience needs to be compelling.</p>

<Image src="images/Alex-cropped.JPG" size="medium" />

<Header as="h3">The DOME Difference.</Header>

<ul class="list">
  <li><b>Dynamic music:</b> realtime musical response to user input and game action.</li>
  <li><b>Non-repetitive music:</b> potentially unlimited variation in musical score.</li>
  <li><b>Many modes of variation:</b> music can change with respect to game environment, character presence, user preferences, or anything else defined by the developer.</li>
  <li><b>Widest-range of application:</b> the system can work with any interactive application, or even non-interactive applications where non-repetitive music is desired.</li>
</ul>

<p>The end result is an enhanced end-user experience: in essence, each individual user will have a unique gaming experience whereby the musical accompaniment (score) is dynamically custom-composed for any particular scenario, but without higher production cost, longer development time, or higher hardware overhead.</p>            
</Container>
        )
    }
}

export default Technology;