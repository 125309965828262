import React, { Component } from "react";

import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Menu,
    Image,
    Segment,
    Form,
    Checkbox
  } from "semantic-ui-react";
  
class Contact extends Component {
    render(){
        return(
            <Grid.Row>
                <Container className="contact-form">
                <Header as="h2">Contact Us</Header>
                <p>If you would like to find out more about the DOME system, please contact us by using the form below. Note that all contact information you submit will remain strictly confidential.</p>
                 <Form action="https://app.99inbound.com/api/e/piiMOYzo" method="POST" target="_blank" style={ {width: "30%"} }>
                     <Form.Field>
                         <label className="form-label">Name</label>
                         <input name="name" placeholder="Name"/>
                     </Form.Field>
                     <Form.Field>
                         <label className="form-label">Company</label>
                         <input name="company" placeholder="Company"/>
                     </Form.Field>
                     <Form.Field>
                         <label className="form-label">Email</label>
                         <input name="email" placeholder="Email"/>
                     </Form.Field>
                     <Form.Field>
                         <label className="form-label">Comments/Questions</label>
                         <textarea name="comments" placeholder="Comments/Questions" />
                     </Form.Field>
                     {/* <Form.Field>
                        <Checkbox label='Keep me informed with the latest DOME news.' />
                    </Form.Field> */}
                    <div style={{position: "absolute", left: "-5000px"}}>
                        <input type="checkbox" name="exciting_peach_houndstooth_address" value="1" tabIndex="-1" autoComplete="no" />
                    </div>
                     <Button type='submit' primary>Submit</Button>
                 </Form>
            </Container>

            </Grid.Row>
        )
    }
}

export default Contact;