import React, { Component } from "react";

import "semantic-ui-css/semantic.min.css";

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Menu,
  Image,
  Segment
} from "semantic-ui-react";

import "./App.css";
import About from './About'
import Technology from "./Technology";
import Contact from "./Contact";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { active: 'Home' }
  }

  handleItemClick = (page) => {
    // console.log(page)
    this.setState( { active: page } )
  }

  render() {
    const { active } = this.state
    return (
      <div className="App">
        <Grid stackable container>
          <Grid.Row>
            {/* <Header size="large" as="h3">
              Dometechnics
            </Header> */}
            <Image src="images/logo-black-325x113.png" />
            <Menu fluid stackable widths="four" size="large">
              <Menu.Item active={ active === 'Home' } onClick={() => this.handleItemClick('Home')}>Home</Menu.Item>
              <Menu.Item active={ active === 'About' } onClick={() => this.handleItemClick('About')}>About</Menu.Item>
              <Menu.Item active={ active === 'Technology' } onClick={() => this.handleItemClick('Technology')}>Technology</Menu.Item>
              <Menu.Item active={ active === 'Contact' } onClick={() => this.handleItemClick('Contact')}>Contact</Menu.Item>
            </Menu>
          </Grid.Row>
          {/* <Divider hidden section /> */}
          {/* <Divider hidden section /> */}
          <Grid.Row>
            <Container textAlign="center">
                <Image src="images/mixer-1500x250.jpg" />
              { active === 'Home' &&
              <div className="welcome" style={ { marginTop: "50px", marginBottom: "50px" } } >
              <Header as="h2">
                Welcome to the future of game music.
              </Header>
              <p>
              Despite radical advances in graphics technology in recent years, the successful development of interactive, real-time music that can respond to game play remains a critical problem in games. Music is both repetitive and irrelevant to events on screen. Dometechnics has developed the solution to this problem through its Dynamic Object Music Engine (DOME).
              </p>
              </div>
            }
            </Container>
          </Grid.Row>
          { active === 'Home' &&
          <Grid.Row columns="two">
            <Grid.Column>
              <Header size="huge" as="h1">
                About
              </Header>
              <p>
              Dometechnics combines expertise in digital music composition, software engineering, and application development.
              </p>
              <Button size="small" primary onClick={() => this.handleItemClick('About')}>
                Read More &raquo;
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Header size="huge" as="h1">
                Technology
              </Header>
              <p>
              The DOME system delivers dynamic, real-time music composition that reflects and responds to user input and game action.
              </p>
              <Button size="small" primary onClick={() => this.handleItemClick('Technology')}>
                Read More &raquo;
              </Button>
            </Grid.Column>
          </Grid.Row>
          }
          { active === 'About' &&
            <About />
          }
          { active === 'Technology' &&
            <Technology />
          }
          { active === 'Contact' &&
            <Contact />
          }
          <Divider hidden section />
          <Grid.Row>
            <Grid.Column>
              <Divider />
              <footer>&copy; 2022 Dometechnics</footer>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default App;
